<div class="sidebar" style="background-color: #0E103D;border: none;">


  <!-- <div class="logo mt-2"><img src="../../../assets/new images/login-upd.png" alt="logo" style=" object-fit: contain;height: 54px;margin-top: 20px;margin-left: 71px;margin-bottom: 35px;"></div> -->
  <div class="logo mt-2"><img src="../../../assets/images/new logo updated (2).png" alt="logo" style="height: 61px;
    width: 202px;
    margin-top: 20px;
    margin-left: 34px;
    margin-bottom: 35px;"></div>


  <div class="header-links">

    <a *ngIf="(rolesCheck.includes('REPORTING') || roleId === 1)" routerLink="/analytics-dashboard"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24"
          style="filter: brightness(0) invert(1);">
      </span>
      <span class="link-text">Report</span>
    </a>

    <a *ngIf="(rolesCheck.includes('CALENDAR') || roleId === 1)" routerLink="/calender-list"
    routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/calendar.png" alt="" height="24" width="24"
        style="filter: brightness(0) invert(1);">
    </span>
    <span class="link-text">Calendar</span>
  </a>

    <!-- <a  routerLink="/service-template" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
    <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
    </span>
    <span class="link-text">Service Template</span>
</a>

<a  routerLink="/vendor-template" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Vendor Template</span>
</a>

<a  routerLink="/vendor-reassign" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Vendor Re-Assign</span>
</a>


<a  routerLink="/Upcoming-service" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Upcoming-service</span>
</a>

<a  routerLink="/service-completed" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Service Completed</span>
</a>

<a  routerLink="/price-modify" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Price modification</span>
</a>

<a  routerLink="/Service-Cancellation" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Service Cancellation</span>
</a>

<a  routerLink="/service-cancel" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Service Cancel</span>
</a>

<a  routerLink="/assign-new" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">Assign New</span>
</a>

<a  routerLink="/sub-admin" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
  </span>
  <span class="link-text">SUB Admin</span>
</a> -->



    <a *ngIf="(rolesCheck.includes('SERVICE_CATEGORIES') || roleId === 1)" routerLink="/manage-serviceCategories"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/service category.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Service Categories</span>
    </a>

    <a *ngIf="(rolesCheck.includes('SERVICES') || roleId === 1)" routerLink="/manage-services"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/images/icons8-service-64.png" alt="" height="31" width="33">
      </span>
      <span class="link-text">Manage Services</span>
    </a>

    <a *ngIf="(rolesCheck.includes('ADON_SERVICES') || roleId === 1)" routerLink="/manage-adOn"
    routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/images/Manage Hospital.svg" alt="" height="24" width="24">
    </span>
    <span class="link-text">Ad-On Services</span>
  </a>

    <a *ngIf="(rolesCheck.includes('Manage-Staff')  || roleId === 1)" routerLink="/manage-prokeepings-staff"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/vendor.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Manage Staffs</span>
    </a>



    <a *ngIf="(rolesCheck.includes('SUB-ADMIN')  || roleId === 1)" routerLink="/manage-admin"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/manage sub-admin.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Manage Sub-Admins</span>
    </a>


    <a *ngIf="(rolesCheck.includes('SERVICE_BOOKINGS')  || roleId === 1)" routerLink="/manage-book"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">

      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/service booking.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Service Bookings</span>
    </a>

    <a *ngIf="(rolesCheck.includes('PROPERTY')  || roleId === 1)" routerLink="/manage-property"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">

      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/property.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Manage Properties</span>
    </a>

    <a *ngIf="(rolesCheck.includes('CommonArea')  || roleId === 1)" routerLink="/manage-commonarea"
    routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">

    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/property.png" alt="" height="24" width="24">
    </span>
    <span class="link-text">Common Area/Units</span>
  </a>

    <a *ngIf="(rolesCheck.includes('INVOICE')  || roleId === 1)" routerLink="/manage-invoice"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">

      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/invoice.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Manage Invoices</span>
    </a>

    <a *ngIf="(rolesCheck.includes('View-Users')  || roleId === 1)" routerLink="/manage-profile"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/view users.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Property Staff</span>
    </a>

    <a *ngIf="(rolesCheck.includes('PAYMENTS')  || roleId === 1)" routerLink="/manage-payments"
      routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/payments.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">View Payments</span>
    </a>


    <!-- <a  routerLink="/quick-books" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/payments.png" alt="" height="24" width="24">
  </span>
  <span class="link-text">Quick Books</span>
</a> -->


    <div *ngIf="(rolesCheck.includes('SETTINGS') || roleId === 1)" class="menu-item" (click)="toggleSubMenu('settings')"
      style="margin-left: 30px;">
      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/manage service.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Settings</span>
      <img *ngIf="!isSettingsSubMenuOpen" src="../../../assets/images/greyicon.png" alt="" class="menu-icon"
        style="margin-left: 90px;cursor: pointer;" />
      <img *ngIf="isSettingsSubMenuOpen" src="../../../assets/images/greyicon.png" alt="" class="menu-icon"
        style="margin-left: 90px;cursor: pointer; transform: rotate(180deg);" />
      <img style="padding-left: 95px;cursor: pointer;" src="../../../assets/icons/arrow.png" alt="" class="arrow"
        [ngClass]="{ 'active-arrow': isSettingsSubMenuOpen }">
    </div>

    <div *ngIf="isSettingsSubMenuOpen" id="submenu-settings" (click)="$event.stopPropagation()">
      <a routerLink="/manage-changePassword" routerLinkActive="active-link" class="submenu-link"
        (click)="highlightChangePassword($event)">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/change paswordd.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Change Password</span>
      </a>
      <a routerLink="/manage-updateProfile" routerLinkActive="active-link" class="submenu-link"
        (click)="highlightUpdateProfile($event)" style="margin-bottom: 15px;">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/update profil.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Update Profile</span>
      </a>
    </div>



    <!-- <div *ngIf="(rolesCheck.includes('ANALYTICS') || roleId === 1)" class="menu-item" (click)="toggleSubMenu('analytics')" style="margin-left: 28px;"> -->
    <div class="menu-item" (click)="toggleSubMenu('analytics')" style="margin-left: 28px;">

      <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/app analytics.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Analytics</span>
      <img *ngIf="!isAnalyticsSubMenuOpen" src="../../../assets/images/greyicon.png" alt="" class="menu-icon"
        style="margin-left: 85px;cursor: pointer;" />
      <img *ngIf="isAnalyticsSubMenuOpen" src="../../../assets/images/greyicon.png" alt="" class="menu-icon"
        style="margin-left: 85px;cursor: pointer; transform: rotate(180deg);" />
    </div>

    <div id="submenu-analytics" [style.display]="isAnalyticsSubMenuOpen ? 'block' : 'none'" style="margin-top:20px">
      <a routerLink="/manage-crash" routerLinkActive="active-link" class="submenu-link" style="margin-left: 21px;">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/crash analytics.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Crash Analytics</span>
      </a>
      <a routerLink="/manage-analytics" routerLinkActive="active-link" class="submenu-link" style="margin-left: 21px;">
        <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/app analytics.png" alt="" height="24" width="24">
        </span>
        <span class="link-text">Google Analytics</span>
      </a>
    </div>



    <a (click)="openModal(template)" routerLinkActive="active-link" id="logout"
      [ngStyle]="{'color': 'rgba(96, 111, 140, 1)', 'margin-top': rolesCheck.includes('SERVICE_CATEGORIES') ? '10px' : '0px' }">
      <span class="sidebar-icon">
        <img style="width: 50px; height: 50px; object-fit: contain; margin-left: -8px;"
          src="../../../assets/images/logoutNewLogo.png" alt="">
      </span>
      <span class="link-text">Logout</span>
    </a>



    <ng-template #template>

      <div class="modal-body text-center delete-modal align-items-center"
        style="background-color: #fff; border-radius: 30px;">

        <img src="../../../assets/images/logoutNewLogo.png" alt=""
          style="height: 100px;width: 190px;margin-left: 0px;object-fit: contain;">
        <p style="color: black; margin-bottom: 20px; margin-top: 10px;">Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-evenly">
          <button class="btn btn-red" (click)="cancel()"
            style="color: #0E103D;background-color: #fff; border-width: 1; border-color: #0E103D;">No</button>
          <button class="btn btn-red" (click)="confirm()" style="color: #fff; background-color: #0E103D;">Yes</button>
        </div>
      </div>
    </ng-template>

  </div>
</div>