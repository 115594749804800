import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { QuickBooksComponent } from "./auth/components/quick-books/quick-books.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "manage-changePassword",
    component: EditProfileComponent,
  }, 
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },
      { path: "quick-books", component: QuickBooksComponent },


      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },

     
      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      // {
      //   path: "quick-books",
      //   loadChildren: () =>
      //     import("../app/auth/components/quick-books/au").then(
      //       (m) => m.DashboardModule
      //     )
      // },

      

      {
        path: "manage-serviceCategories",
        loadChildren: () =>
          import("../app/views/manage-guest/manage-guest.module").then(
            (m) => m.ManageGuestModule
          )
      },


      {
        path: "manage-admin",
        loadChildren: () =>
          import("../app/views/manage-sub-admin/manage-sub-admin.module").then(
            (m) => m.ManageSubAdminModule
          )
      },
     

      {
        path: "manage-services",
        loadChildren: () =>
          import("../app/views/manage-host/manage-host.module").then(
            (m) => m.ManageCaregiversModule
          )
      }, 

      {
        path: "manage-commonarea",
        loadChildren: () =>
          import("../app/views/manage-buildings/manage-buildings.module").then(
            (m) => m.ManageBuildingsModule
          )
      }, 

      {
        path: "manage-bookings",
        loadChildren: () =>
          import("../app/views/manage-bookings/manage-bookings.module").then(
            (m) => m.ManageBookingsModule
          )
      }, 

      {
        path: "manage-prokeepings-staff",
        loadChildren: () =>
          import("../app/views/vendor-module/vendor-module.module").then(
            (m) => m.VendorModuleModule
          )
      },

      {
        path: "manage-book",
        loadChildren: () =>
          import("../app/views/manage-book/manage-book.module").then(
            (m) => m.ManageBookModule
          )
      },

      {
        path: "manage-profile",
        loadChildren: () =>
          import("../app/views/manage-view/manage-view.module").then(
            (m) => m.ManageViewModule
          )
      },

      
      {
        path: "manage-payments",
        loadChildren: () =>
          import("../app/views/manage-payments/manage-payments.module").then(
            (m) => m.ManagePaymentsModule
          )
      },

      {
        path: "manage-updateProfile",
        loadChildren: () =>
          import("../app/views/manage-settings/manage-settings.module").then(
            (m) => m.ManageSettingsModule
          )
      },

      {
        path: "manage-property",
        loadChildren: () =>
          import("../app/views/manage-property/manage-property.module").then(
            (m) => m.ManagePropertyModule
          )
      },

      {
        path: "manage-invoice",
        loadChildren: () =>
          import("../app/views/manage-invoice/manage-invoice.module").then(
            (m) => m.ManageInvoiceModule
          )
      },

      {
        path: "manage-crash",
        loadChildren: () =>
          import("../app/views/manage-crash/manage-crash.module").then(
            (m) => m.ManageCrashModule
          )
      },

      {
        path: "manage-analytics",
        loadChildren: () =>
          import("../app/views/manage-analytics/manage-analytics.module").then(
            (m) => m.ManageServiceModule
          )
      },

      {
        path: "calender-list",
        loadChildren: () =>
          import("../app/views/calender/calender.module").then(
            (m) => m.CalenderModule
          )
      },



      
     
  {
        path: "manage-adOn",
        loadChildren: () =>
          import("../app/views/manage-ad-on/manage-ad-on.module").then(
            (m) => m.ManageAdOnModule
          )
      },

    

      
    

    

    

    

     
      


      
     
    
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

