<app-layout></app-layout>
<div class="content">
    <div style="margin-bottom: 30px;">
        <!-- <mat-form-field style="margin-right: 20px;">
            <mat-label style="color: black; font-size: 15px;">Filter By:</mat-label>
            <mat-select [(value)]="selectedFilter" (selectionChange)="onChangeCategory($event.value)">
                <mat-option value="all">All</mat-option>
                <mat-option value="daily">Daily</mat-option>
                <mat-option value="weekly">Weekly</mat-option>
                <mat-option value="monthly">Monthly</mat-option>
                <mat-option value="annually">Annually</mat-option>
            </mat-select>
        </mat-form-field> -->
        <div style="display: flex; align-items: center;">
            <!-- Filter Section -->
            <div style="display: flex; flex-direction: column; align-items: flex-start; margin-right: 10px;">
                <!-- Label for Filter By -->
                <label 
                  style="font-size: 12px; color: #0E103D; margin-bottom: 5px; margin-left: 7px;">
                  Filter By:
                </label>
                
                <!-- Dropdown Button -->
                <div style="position: relative; display: inline-block; width: 100%;">
                  <button 
                    class="btn btn-primary" 
                    (click)="toggleDropdown()" 
                    style="width: 114px; padding: 5px 10px; font-size: 12px; border-radius: 15px; cursor: pointer; background-color: white; border: 1px solid #0E103D; color: #0E103D;">
                    {{ selectedFilter || 'Select All' }} ▼
                  </button>
                  
                  <!-- Dropdown Menu -->
                  <div *ngIf="dropdownVisible" id="dropdownMenu" 
                    style="position: absolute; top: 100%; left: 0; width: 100%; background-color: #fff; border: 1px solid #ccc; z-index: 100; border-radius: 5px; margin-top: 5px;">
                    <div style="padding: 10px; cursor: pointer;" (click)="onChangeCategory('all')">All</div>
                    <div style="padding: 10px; cursor: pointer;" (click)="onChangeCategory('daily')">Daily</div>
                    <div style="padding: 10px; cursor: pointer;" (click)="onChangeCategory('weekly')">Weekly</div>
                    <div style="padding: 10px; cursor: pointer;" (click)="onChangeCategory('monthly')">Monthly</div>
                    <div style="padding: 10px; cursor: pointer;" (click)="onChangeCategory('annually')">Annually</div>
                  </div>
                </div>
              </div>
              
          
            <!-- Date Picker Section -->
            <div style="display: flex; align-items: center; gap: 10px;    margin-top: -1px;">
              <!-- Start Date -->
              <div style="display: flex; flex-direction: column; align-items: center;">
                <label for="startDate" style="font-size: 12px; color: #0E103D; margin-bottom: 5px;margin-left: -66px;">From:</label>
                <input
                  type="date"
                  id="startDate"
                  class="form-control"
                  [(ngModel)]="startDate"
                  (change)="onDateChange()"
                  max="{{ today }}"
                  style="width: 111px; font-size: 10px; padding: 5px; height: 30px; border: 1px solid #0E103D; border-radius: 15px; background-color: white;"/>
              </div>
          
              <!-- To Date -->
              <div style="display: flex; flex-direction: column; align-items: center;">
                <label for="endDate" style="font-size: 12px; color: #0E103D; margin-bottom: 5px;margin-left: -66px;">To:</label>
                <input
                  type="date"
                  id="endDate"
                  class="form-control"
                  [(ngModel)]="endDate"
                  (change)="onDateChange()"
                  max="{{ today }}"
                  style="width: 111px; font-size: 10px; padding: 5px; height: 30px; border: 1px solid #0E103D; border-radius: 15px; background-color: white;"/>
              </div>
          
              <!-- Reset Icon -->
              <div  style="display: flex; flex-direction: column; align-items: center; margin-left: 10px;margin-top: 25px;">
                <div (click)="clearFilters()" style="height: 20px; width: 20px; cursor: pointer;">
                  <img src="../../../../../assets/images/2618245.png" alt="Clear Filters" style="height: 100%; width: 100%;" />
                </div>
                <label for="clearFilters" style="font-size: 10px; color: #0E103D;">Reset</label>
              </div>
            </div>
          </div>
          
          
          <!-- Error Message -->
          <div *ngIf="dateError" class="text-danger mt-2">
            End date must be greater than or equal to the start date.
          </div>
          
          
          
    </div>
    

    <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 15px;">
        <div style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 40px;">
            <div routerLink="/manage-profile/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Registered Customers</h3>
                <p style="font-weight: bold; font-size: 28px;">{{RegisteredCustomers}}</p>
            </div>
            <div routerLink="/manage-book/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Bookings Received</h3>
                <p style="font-weight: bold;font-size: 28px;">{{RegisteredBookings}}</p>
            </div>
            <div routerLink="/manage-payments/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Revenue Generated</h3>
                <p style="font-weight: bold;font-size: 28px;">0</p>
            </div>
        </div>
    </div>
</div>

<ng-template #templateImage1>
    <div class="modal-header">
        <button type="button" class="close pull-right close-button-black-border" aria-label="Close" (click)="modalRef.hide(); reset()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img src="../../../assets/new images/quick.png" alt="Quick Image" class="quickbooks-image">
        <button class="quickbooks-button" (click)="quickBooks()">Connect to QuickBooks</button>
    </div>
</ng-template>


  
  
  