import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { ManageSettingsService } from './manage-settings.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-manage-settings',
  templateUrl: './manage-settings.component.html',
  styleUrls: ['./manage-settings.component.scss']
})
export class ManageSettingsComponent implements OnInit {
  @ViewChild("myFile2") myFile2: ElementRef;

  hide = true;
  hide1 = true;
  hide2 = true;
  userType: any;
  message: string;
  imagePath: any;
  imgURL: any;
  uploaded: boolean = false;
  fileName: any;
  currentUser: any;
  page: number;
  lang: string;
  currentPage: number;
  mainEmailId: string;
  strongPasswordError: boolean = false;
  samePasswordError: boolean = false;
  allFieldsEntered = false;
  password: boolean = false;
  passChangeMsg: boolean = false;
  passwordForm: FormGroup;
  

  constructor(
    private _header: HeaderService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private service: ManageSettingsService,
    private fb: FormBuilder,
    private authService: AuthService,
  ) { }
  mainName: any
  roleId: any
  ngOnInit(): void {
    this.lang = localStorage.getItem("lang");
    this._header.manageSettingsHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.roleId = this.currentUser?.profile?.role;
    console.log('roleId111',this.roleId)
    this.imgURL = "../../../../../assets/icons/img-thumb.svg";
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.mainName = this.currentUser?.profile?.firstName;
    this.mainEmailId = this.currentUser?.profile?.email;
    console.log('mainName', this.currentUser?.profile?.firstName);

    if (this.page) {
      this.currentPage = this.page;
    }

    this.passwordForm = this.fb.group({
      name: [ this.mainName || '', [Validators.required, Validators.minLength(6)]],
      email: [{ value: this.mainEmailId, disabled: this.roleId === 1 ? false : true }, [Validators.required, Validators.email]]
    });

    this.passwordForm.get('name').valueChanges.subscribe(value => {
      this.allFieldsEntered = value.trim() !== '' && value !== this.mainName;
    });
  }

  ngOnDestroy() {
    this._header.manageSettingsHeading.next(false);
  }
match:boolean=true;
  checkFieldsEntered() {
    const { oldPassword, newPassword, confirmPassword } = this.passwordForm.value;
    this.allFieldsEntered = oldPassword && newPassword && confirmPassword;
    this.match = newPassword === confirmPassword;
    this.samePasswordError = oldPassword === newPassword;

    const newPasswordControl = this.passwordForm.get('newPassword');
    if (newPasswordControl.errors) {
      this.strongPasswordError = newPasswordControl.errors.pattern;
    } else {
      this.strongPasswordError = false;
    }
  }

  get p() {
    return this.passwordForm.controls;
  }

  isNewPasswordSameAsOldPassword() {
    const oldPassword = this.passwordForm.get('oldPassword').value;
    const newPassword = this.passwordForm.get('newPassword').value;

    this.password = oldPassword === newPassword;
  }

  passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const oldPassword = control.get('oldPassword').value;
      const newPassword = control.get('newPassword').value;

      if (oldPassword !== newPassword) {
        return null;
      }

      return { samePassword: true };
    };
  }

  openModal(template: TemplateRef<any>) {
 //intentionally left space for future use
  }

  changePassword() {
    if (!this.allFieldsEntered) return;
    let data = {
      email: this.mainEmailId,
      role: 1,
      name: this.passwordForm.value.name
    };

    this.service.updateProfile(data).subscribe(
      (res: any) => {
        console.log('changepassword res', res);
        if (res.message) {
          this.toastrService.success("Profile Updated Successfully");
        }
      },
      (err) => {
        this.toastrService.error("Name field should not be empty");
      
      }
    );
  }
}
