import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  manageInterestdHeading:boolean = false;
  managePropertyHeading:boolean = false;
  
  manageLocalHeading:boolean = false;
  broadcastNotificationHeading:boolean=false;
  manageCaregiversHeading: boolean = false;
  manageAreaHeading: boolean = false;
  manageAdonHeading: boolean = false;
  manageVendorsHeading: boolean = false;
  manageViewHeading: boolean = false;
  managePaymentHeading: boolean = false;
  manageSettingsHeading:boolean = false;
  manageHostHeading: boolean = false;
  manageSubAdminHeading: boolean = false;
  manageBookingHeading: boolean = false;
  manageClientHeading:boolean = false;
  manageInvoiceHeading:boolean = false;
  manageServicesHeading: boolean = false;
  manageTutorialHeading:boolean = false;
  manageConvenienceHeading:boolean = false;
  manageDiseasesHeading:boolean = false;
  manageCalenderHeading:boolean = false;
  manageBookHeading:boolean = false;
  manageBookviewHeading:boolean = false;



  welcomeUserMsg: boolean = false;
  hospitalHeading: boolean = false;
  loginHeading: boolean = false;
  usageHeading: boolean = false;
  supportHeading: boolean = false;
  feedbackHeading: boolean = false;
  manageHeading: boolean = false;
  aboutHeading:boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false;
  pushNotificationHeading: boolean = false;
  addUserHeading: boolean = false;
  privacyPolicyHeading: boolean = false;
  surveyAnswersHeading: boolean = false;





  importedEventsHeading: boolean = false;
  subEventsHeading: boolean = false;
  zoneHeading: boolean = false;
  subZoneHeading: boolean = false;
  venueHeading: boolean = false;
  interestHeading: boolean = false;
  pointHeading: boolean = false;
  placeHeading: boolean = false;
  merchandiseHeading: boolean = false;
  sectionHeading: boolean = false;
  categoryHeading: boolean = false;
  pointcategoryHeading: boolean = false;
  homeScreenPartnersHeading: boolean = false;
  eventsponsorsHeading: boolean = false;
  notificationHeading: boolean = false;
  protocolHeading: boolean = false;
  seasonsHeading: boolean = false;
  chatListHeading: boolean = false;
  chatDetailsHeading: boolean = false;
  requestHeading: boolean = false;
  settingHeading: boolean = false;
  restaurantsHeading: boolean = false;
  requestsHeading: boolean = false;
  customerHeading: boolean = false;
  zoneRequestHeading: boolean = false;
  zoneChatHeading: boolean = false;
  zoneCallHeading: boolean = false;
  zoneReportingHeading: boolean = false;
  EventRequestHeading: boolean = false;
  BannerHeading: boolean = false;
  CuisineHeading: boolean = false;
  PopupHeading: boolean = false;
  PdfHeading: boolean = false;
  TreasureHeading: boolean = false;
  CouponHeading: boolean = false;
  ZoneCoordinatesHeading: boolean = false;
  SubZoneCoordinatesHeading: boolean = false;
  emailSupportHeading:boolean = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: string = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {


    


    this._header.manageCaregiversHeading.subscribe((res) => {
      this.manageCaregiversHeading = res;
    });

    this._header.manageAreaHeading.subscribe((res) => {
      this.manageAreaHeading = res;
    });

    

    this._header.manageAdonHeading.subscribe((res) => {
      this.manageAdonHeading = res;
    });
    


    this._header.manageSettingsHeading.subscribe((res) => {
      this.manageSettingsHeading = res;
    })

    this._header.managePropertyHeading.subscribe((res) => {
      this.managePropertyHeading = res;
    })


    this._header.manageBookHeading.subscribe((res) => {
      this.manageBookHeading = res;
    })
    

    

    this._header.managePaymentHeading.subscribe((res) => {
      this.managePaymentHeading = res;
    });

    this._header.manageBookviewHeading.subscribe((res) => {
      this.manageBookviewHeading = res;
    });


    

    this._header.manageViewHeading.subscribe((res) => {
this.manageViewHeading = res;
    });

    this._header.manageVendorsHeading.subscribe((res) => {
      this.manageVendorsHeading = res;
    });
    


       this._header.manageSubAdminHeading.subscribe((res) => {
      this.manageSubAdminHeading = res;
    }); 

    this._header.manageHostHeading.subscribe((res) => {
      this.manageHostHeading = res;
    });

    this._header.manageBookingHeading.subscribe((res) => {
      this.manageBookingHeading = res;
    });
    

    this._header.manageClientHeading.subscribe((res) => {
      this.manageClientHeading = res;
    });

    this._header.manageInvoiceHeading.subscribe((res) => {
      this.manageInvoiceHeading = res;
    });
    


    this._header.manageServicesHeading.subscribe((res) => {
      this.manageServicesHeading = res;
    });

    this._header.manageTutorialHeading.subscribe((res) => {
      this.manageTutorialHeading = res;
    });


    this._header.manageConvenienceHeading.subscribe((res) => {
      this.manageConvenienceHeading = res;
    });

    this._header.manageCalenderHeading.subscribe((res) => {
      this.manageCalenderHeading = res;
    });


    this._header.manageDiseasesHeading.subscribe((res) => {
      this.manageDiseasesHeading = res;
    });



  

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });


    this._header.manageInterestdHeading.subscribe((res) => {
      this.manageInterestdHeading = res;
    });
    this._header.broadcastNotificationHeading.subscribe((res) => {
      this.broadcastNotificationHeading= res;
    });
    




    this._header.manageLocalHeading.subscribe((res) => {
      this.manageLocalHeading = res;
    });


    

    





    

   
  }

  userName: string;
  firstName:string;
  lastName: string;
  allDate:any;
  lasName:string;


  currentUser:any;
  profile:any;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
   

    this.allDate = this.authService.currentUserValue;
    this.userName = this.profile?.firstName;
    this.setUserData();

  }
  roleId:any;
  
  setUserData() {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.profile) {
      this.userName = currentUser.profile.firstName;
      this.lasName = currentUser.profile.lastName;
      this.roleId=this.currentUser?.profile?.role;
  console.log("currentUser",this.currentUser.profile)
      // Check if profilePicture exists and is not empty or null
      this.profilePic = currentUser.profile.profilePicture 
        ? currentUser.profile.profilePicture 
        : '../../../assets/new images/Group 1321314701.png';
  
    } else {
      this.userName = "Admin"; // default value
      this.profilePic = '../../../assets/new images/Group 1321314701.png'; // Default profile picture
    }
  }
  

  logout() {
    this.modalRef.hide();
  
  }
  

 

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }
}

